body {
    margin: 0;
}

.react-p5-wrapper .p5Canvas {
    // horizontally center canvas
    display: block;
    margin: 0 auto;
}

// Help dialog image
img.help-dialog-image {
    display: block;
    width: 90%;
    margin: 6px auto;
    // Banner image
    &.banner-image {
        width: 100%;
    }
}